var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$options.components['advertisement-loading'] ? [_vm.isLoading ? _c('advertisement-loading') : _vm._e()] : _vm._e(), _c('div', {
    attrs: {
      "else": ""
    }
  }, [_c('sections-slider', {
    staticClass: "suggested-slider",
    attrs: {
      "list": _vm.newArrivals,
      "subTitle": "New Arrivals",
      "hasBackground": true,
      "hasButton": false,
      "showTitle": true,
      "withoutSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var listItem = _ref.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          staticClass: "suggester-div w-100",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }])
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }