<template>
  <div>
    <template v-if="$options.components['advertisement-loading']">
      <advertisement-loading v-if="isLoading" />
    </template>
    <div else>
      <sections-slider
        :list="newArrivals"
        :subTitle="`New Arrivals`"
        :hasBackground="true"
        :hasButton="false"
        :showTitle="true"
        :withoutSlider="true"
        class="suggested-slider"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div w-100"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  computed: {
    ...mapState({
      currentPosition: (state) => state.location.currentPosition,
      newArrivals: (state) => state["home"].newArrivals,
    }),
  },
  methods: {
    ...mapActions({
      fetchMarketItemsData: "home/fetchMarketItemsData",
    }),
  },
  mounted() {
    this.fetchMarketItemsData();
  },
};
</script>
<style scoped>
.product-listing-div {
  display: block !important;
  width: 100% !important;
}
</style>
